import Button from '@/components/global/Button';
import Icon from '@/components/global/Icon';

const ButtonWrapper = ({ blok }: any) => {
  const {
    position,
    variant,
    size,
    fit_width_to_content,
    icon,
    textColor,
    ariaLabel,
    disabled,
    className
  } = blok;
  return (
    <Button
      href={blok?.link?.cached_url}
      target={blok?.link?.target}
      variant={variant}
      size={size}
      align={position}
      fit_width_to_content={fit_width_to_content}
      textColor={textColor}
      ariaLabel={ariaLabel}
      disabled={disabled}
      className={className}
    >
      {icon ? (
        <>
          {blok.label}
          <Icon iconName={icon} />
        </>
      ) : (
        blok.label
      )}
    </Button>
  );
};

export default ButtonWrapper;
